<template>
  <el-dialog
    :visible.sync="visible"
    :title="title"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="70%"
    @close="closeHandle"
  >
    <div class="location-map" id="location-map" ref="location-map"></div>
    <template slot="footer">
      <!-- <el-button type="primary" @click="dataFormSubmitHandle()">确认</el-button> -->
      <el-button @click="visible = false">取消</el-button>
    </template>
  </el-dialog>
</template>
<script>
import L from "leaflet/dist/leaflet";
import "leaflet.chinatmsproviders";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet.markercluster";
import onlineIconPng from "@/assets/img/online-battery-icon.png";
const onlineIcon = L.icon({
  iconUrl: onlineIconPng,
});
import "./map-location.less";
export default {
  data() {
    return {
      title: null,
      map: null,
      markers: null,
      visible: false,
      latitude: null,
      longitude: null,
    };
  },
  methods: {
    //初始化
    init() {
      this.visible = true;
      this.$nextTick(() => {
        this.initMap();
        this.drawMaker();
      });
    },
    //关闭弹窗
    closeHandle() {
      // this.markers.clearLayers();
      // this.map = null
      this.map.off();
      this.map.remove();
    },
    initMap() {
      const normalm = L.tileLayer.chinaProvider("GaoDe.Normal.Map", {
        maxZoom: 15,
        minZoom: 0,
      });
      const normal = L.layerGroup([normalm]);
      const map = L.map("location-map", {
        center: [40.125721, 116.320473],
        zoom: 5,
        layers: [normal],
        zoomControl: false,
      });
      this.map = map;
    },
    drawMaker() {
      if(this.latitude) {
        const point = L.latLng(this.latitude, this.longitude);
        const marker = L.marker(point, {
          icon: onlineIcon,
          autoPan: true,
        }).addTo(this.map);
      }
      
    },
  },
};
</script>