<template>
  <div class="detail-container">
    <div class="device-detail-title">
      <i class="el-icon-arrow-left" @click="backToList"></i>
      <span>设备详情</span>
    </div>
    <i class="el-icon-arrow-down" v-if="!showBase" @click="showMoreHandle"></i>
    <i class="el-icon-arrow-up" v-if="showBase" @click="showMoreHandle"></i>
    <template v-if="showBase">
      <div class="data-base-info">
        <div class="data-base-title">资产基础信息</div>
        <el-row class="data-row">
          <el-col :span="3">
            <div class="data-sn">设备编号</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content" v-if="organId === '99'">{{ pageData.pfsn || "-" }}</div>
            <div class="data-content" v-else>{{ pageData.sn || "-" }}</div>
          </el-col>
          <el-col :span="3">
            <div class="data-sn">产品类别</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content">{{ pageData.category || "-" }}</div>
          </el-col>
          <el-col :span="3">
            <div class="data-sn">产品类型</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content">{{ pageData.type || "-" }}</div>
          </el-col>
          <el-col :span="3">
            <div class="data-sn">厂家</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content">{{ pageData.factory || "-" }}</div>
          </el-col>
        </el-row>
        <el-row class="data-sub-row">
          <el-col :span="3">
            <div class="data-sn">电芯</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content">{{ pageData.cellFactory || "-" }}</div>
          </el-col>
          <el-col :span="3">
            <div class="data-sn">电芯类型</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content">{{ pageData.cellType || "-" }}</div>
          </el-col>
          <el-col :span="3">
            <div class="data-sn">电压平台</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content">{{ pageData.voltPlatform || "-" }}</div>
          </el-col>
          <el-col :span="3">
            <div class="data-sn">容量</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content">{{ pageData.capacity || "-" }}</div>
          </el-col>
        </el-row>
        <el-row class="data-sub-row">
          <el-col :span="3">
            <div class="data-sn">生产时间</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content">{{ pageData.productTime || "-" }}</div>
          </el-col>
          <el-col :span="3">
            <div class="data-sn">投营时间</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content">{{ pageData.operationTime || "-" }}</div>
          </el-col>
          <el-col :span="3">
            <div class="data-sn">采购价格</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content">{{ pageData.price || 0 }}</div>
          </el-col>
          <el-col :span="3">
            <div class="data-sn">电池模式</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content">
              {{ pageData.rentalMode | rentalModeStr }}
            </div>
          </el-col>
        </el-row>
        <el-row class="data-sub-row">
          <el-col :span="3">
            <div class="data-sn">当前租期</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content">
              {{
                  pageData.rentalStartTime
                    ? pageData.rentalStartTime.substring(0, 10) +
                    "至" +
                    pageData.rentalEndTime.substring(0, 10)
                    : "-"
              }}
            </div>
          </el-col>
          <el-col :span="3">
            <div class="data-sn">SOC</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content">{{ pageData.soc || "-" }}</div>
          </el-col>
          <el-col :span="3">
            <div class="data-sn" v-if="organId === '99'">SOH</div>
            <div class="data-sn" v-else>当前状态</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content" v-if="organId === '99'">
              {{ pageData.soh || "-" }}
            </div>
            <div class="data-content" v-else>
              {{ pageData.locked === 0 ? "解锁" : "锁定" }}
            </div>
          </el-col>
          <el-col :span="3">
            <div class="data-sn" v-if="organId === '99'">电池运行状态</div>
            <div class="data-sn" v-else>运行状态</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content" v-if="organId === '99'">
              {{ pageData.runStatus | runStatusStr }}
            </div>
            <div class="data-content" v-else>
              {{ pageData.runStatus | chargeStateStr }}
            </div>
          </el-col>
        </el-row>
        <el-row class="data-sub-row">
          <el-col :span="3">
            <div class="data-sn">当前位置</div>
          </el-col>
          <el-col :span="9">
            <div class="data-content" @click="clickHandle">
              {{ pageData.location || "-" }}
            </div>
          </el-col>
          <el-col :span="3">
            <div class="data-content" @click="clickHandle">
              <img style="height: 20px; width: 20px; padding: 5px 0" src="@/assets/img/nav-maintenance-active.png" />
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="diagnosis-button">
        <div class="buttons-title">诊断工具</div>
        <div class="buttons-time">
          <el-date-picker v-model="search.times" type="datetimerange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" @change="timeChanges">
          </el-date-picker>
        </div>

        <div class="buttons-content">
          <div class="buttons-items">
            <div class="first-item" @click="diagHandle(1)" v-if="organId !== '99'" style="cursor: pointer">
              无法充电诊断
            </div>
            <div class="second-item" @click="diagHandle(2)" v-if="organId !== '99'" style="cursor: pointer">
              无法放电/运行断电
            </div>
            <!-- <div class="third-item">安全诊断</div> -->
          </div>
          <div class="buttons-items">
            <div class="first-item" @click="diagHandle(3)" style="cursor: pointer">
              电池体检
            </div>
            <div class="second-item" v-if="organId === '99'" style="cursor: pointer; background:none; width: 100px; color:black">
              当前状态：<strong>{{Bmslock*1?'闭合':'断开'}}</strong>
            </div>
             <div class="second-item" v-if="organId === '99'" style="cursor: pointer; background:none; width: 80px;">
              
              <el-button
                type="primary"
                @click="BmssetBatterye()"
                :disabled="Bmslock*1 === 0"
                :loading="loading"
                :style="{height:'35px'}"
                >断开</el-button>
            </div> 
            <div class="second-item" v-if="organId === '99'" style="cursor: pointer; background:none; width: 80px;">
              <el-button
                :style="{height:'35px'}"
                type="primary"
                @click="BmssetBatterye()"
                :loading="loading"
                :disabled="Bmslock*1 === 1"
                >闭合</el-button>
            </div>
            
            <div class="second-item" @click="diagHandle(4)" v-if="organId !== '99'" style="cursor: pointer">
              里程检测
            </div>
            <!-- <div class="third-item">健康诊断</div> -->
          </div>
        </div>
      </div>
    </template>
    <div class="detail-infos">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="基本状态信息" name="first">
          <div class="msg-list-tools">
            <el-form class="battery-form" :inline="true" :model="search">
              <el-form-item label="时间范围:">
                <el-date-picker v-model="search.baseTime" type="datetimerange" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期" @change="timeChangeBase">
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-select v-model="search.baseType" @change="baseSelectHandle">
                  <el-option label="图表" :value="1"></el-option>
                  <el-option label="列表" :value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-if="search.baseType === 2">
                <el-select v-model="search.baseLimit" @change="baseLimitSelectHandle">
                  <el-option label="100" :value="100"></el-option>
                  <el-option label="500" :value="500"></el-option>
                  <el-option label="1000" :value="1000"></el-option>
                  <el-option label="5000" :value="5000"></el-option>
                </el-select>
              </el-form-item>
              <!-- <div style="float: right" v-if="organId === '99'">
                <el-form-item>
                  <el-button type="primary" size="small" @click="exportHandle"
                    >导出</el-button
                  >
                </el-form-item>
              </div> -->
            </el-form>
          </div>
          <template v-if="search.baseType === 1">
            <div class="charts">
              <div>
                <div style="color: #2e9afe; font-size: 12px; margin-bottom: 20px">
                  【SOC】
                </div>
                <el-table style="width: 750px" height="300px" v-loading="chartsShow" v-if="chartsShow"></el-table>
                <div v-else ref="socCharts" style="width: 750px; height: 300px"></div>
              </div>
              <div style="margin-left: 30px">
                <div style="color: #2e9afe; font-size: 12px; margin-bottom: 20px">
                  【SOH】
                </div>
                <el-table style="width: 750px" height="300px" v-loading="chartsShow" v-if="chartsShow"></el-table>
                <div v-else ref="sohChatrs" style="width: 750px; height: 300px"></div>
              </div>
            </div>
            <div class="charts">
              <div>
                <div style="color: #2e9afe; font-size: 12px; margin-bottom: 20px">
                  【单体温度】
                </div>
                <el-table style="width: 750px" height="300px" v-loading="chartsShow" v-if="chartsShow"></el-table>
                <div v-else ref="cellTemp" style="width: 750px; height: 300px"></div>
              </div>
              <div style="margin-left: 30px">
                <div style="color: #2e9afe; font-size: 12px; margin-bottom: 20px">
                  【其他温度】
                </div>
                <el-table style="width: 750px" height="300px" v-loading="chartsShow" v-if="chartsShow"></el-table>
                <div v-else ref="otherTemp" style="width: 750px; height: 300px"></div>
              </div>
            </div>
            <div class="charts">
              <div style="margin-left: 30px">
                <div style="color: #2e9afe; font-size: 12px; margin-bottom: 20px">
                  【压差】
                </div>
                <el-table style="width: 750px" height="300px" v-loading="chartsShow" v-if="chartsShow"></el-table>
                <div v-else ref="voltDiffChatrs" style="width: 750px; height: 300px"></div>
              </div>
              <div style="margin-left: 30px">
                <div style="color: #2e9afe; font-size: 12px; margin-bottom: 20px">
                  【总电压】
                </div>
                <el-table style="width: 750px" height="300px" v-loading="chartsShow" v-if="chartsShow"></el-table>
                <div v-else ref="voltCharts" style="width: 750px; height: 300px"></div>
              </div>
            </div>
            <div class="charts">
              <div>
                <div style="color: #2e9afe; font-size: 12px; margin-bottom: 20px">
                  【最高/最低电压】
                </div>
                <el-table style="width: 750px" height="300px" v-loading="chartsShow" v-if="chartsShow"></el-table>
                <div v-else ref="maxminVolt" style="width: 750px; height: 300px"></div>
              </div>
              <div style="margin-left: 30px">
                <div style="color: #2e9afe; font-size: 12px; margin-bottom: 20px">
                  【最高/最低温度】
                </div>
                <el-table style="width: 750px" height="300px" v-loading="chartsShow" v-if="chartsShow"></el-table>
                <div v-else ref="maxminTemp" style="width: 750px; height: 300px"></div>
              </div>
            </div>
            <div class="charts">
              <div>
                <div style="color: #2e9afe; font-size: 12px; margin-bottom: 20px">
                  【总电流】
                </div>
                <el-table style="width: 750px" height="300px" v-loading="chartsShow" v-if="chartsShow"></el-table>
                <div v-else ref="currentCharts" style="width: 750px; height: 300px"></div>
              </div>
              <div>
                <div style="color: #2e9afe; font-size: 12px; margin-bottom: 20px">
                  【单体电压】
                </div>
                <el-table style="width: 750px" height="300px" v-loading="chartsShow" v-if="chartsShow"></el-table>
                <div v-else ref="cellVolt" style="width: 750px; height: 300px"></div>
              </div>
            </div>
          </template>
          <div v-else>
            <el-table style="width: 100%" height="800px" v-loading="baseLoading"
              :header-cell-style="{ fontSize: '10px' }" :data="baseList" v-if="organId === '99'">
              <el-table-column label="时间" fixed width="200">
                <template slot-scope="scope">
                  {{
                      (scope.row.infoDto && scope.row.infoDto.formatObdTime) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="SOC" prop="sender">
                <template slot-scope="scope">
                  {{
                      Number(
                        scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.soc
                      ) || 0
                  }}
                </template>
              </el-table-column>
              <el-table-column label="SOC(2)" prop="sender">
                <template slot-scope="scope">
                  {{
                      Number(
                        scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.pfSoc
                      ).toFixed(1) || 0
                  }}
                </template>
              </el-table-column>
              <el-table-column label="电芯最高温度" prop="status">
                <template slot-scope="scope">
                  {{
                      Number(
                        scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.maxTemp
                      ) || 0
                  }}
                </template>
              </el-table-column>
              <el-table-column label="电芯最低温度" prop="status">
                <template slot-scope="scope">
                  {{
                      Number(
                        scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.minTemp
                      ) || 0
                  }}
                </template>
              </el-table-column>
              <el-table-column label="电芯最高温度ID" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.maxTempCell) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="电芯最低温度ID" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.minTempCell) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="压差" prop="status">
                <template slot-scope="scope">
                  {{
                      Number(
                        scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.cellVoltageDiff
                      ) || 0
                  }}
                </template>
              </el-table-column>
              <el-table-column label="总电压" prop="status">
                <template slot-scope="scope">
                  {{
                      Number(
                        scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.voltageInner
                      ) || 0
                  }}
                </template>
              </el-table-column>
              <el-table-column label="最高电压" prop="status">
                <template slot-scope="scope">
                  {{
                      Number(
                        scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.maxVolt
                      ) || 0
                  }}
                </template>
              </el-table-column>
              <el-table-column label="最低电压" prop="status">
                <template slot-scope="scope">
                  {{
                      Number(
                        scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.minVolt
                      ) || 0
                  }}
                </template>
              </el-table-column>
              <el-table-column label="最高电芯电压ID" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.maxVoltCell) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="最低电芯电压ID" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.minVoltCell) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="总电流" prop="status">
                <template slot-scope="scope">
                  {{
                      Number(
                        scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.current
                      ) || 0
                  }}
                </template>
              </el-table-column>
              <el-table-column label="充放电功率" prop="status">
                <template slot-scope="scope">
                  {{
                      Number(
                        scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.chargePower
                      ) || 0
                  }}
                </template>
              </el-table-column>
              <el-table-column label="进出水口温度" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.inOutWaterTemp) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="总电压(交流侧)" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.voltAC) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="绝缘电阻(kΩ)" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.highResistance) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="充放电能量累计值" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.chargeTotalPower) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="DTC错误等级" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.errorLevel) ||
                      "-"
                  }}
                </template>
              </el-table-column>
            </el-table>
            <el-table style="width: 100%" height="800px" v-loading="baseLoading"
              :header-cell-style="{ fontSize: '10px' }" :data="baseList" v-else>
              <el-table-column label="上传时间" fixed width="200">
                <template slot-scope="scope">
                  {{
                      (scope.row.infoDto && scope.row.infoDto.formatObdTime) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="信号强度" prop="sender">
                <template slot-scope="scope">
                  {{
                      Number(
                        scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.rssi
                      ) || 0
                  }}
                </template>
              </el-table-column>
              <el-table-column label="故障等级" prop="sendTime">
                <template slot-scope="scope">
                  {{
                      Number(
                        scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.errorLevel
                      ) || 0
                  }}
                </template>
              </el-table-column>
              <el-table-column label="故障代码" prop="status">
                <template slot-scope="scope">
                  {{
                      Number(
                        scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.errorCode
                      ) || 0
                  }}
                </template>
              </el-table-column>
              <el-table-column label="电流[A]" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.current) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="总电压[V]" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.voltageInner) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="SOC[%]" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.soc) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="SOH[%]" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.soh) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="总开关" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.switchState) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="加热" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.heatState) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="充电状态" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.chargeState) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="单体均衡" prop="status">
                <template slot-scope="scope">
                  {{
                      Number(
                        scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.cellVolBalance
                      ) || 0
                  }}
                </template>
              </el-table-column>
              <el-table-column label="单体压差[mV]" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.cellVoltageDiff) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="单体电压1[V]" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.cellVoltageList &&
                        scope.row.ffBatteryStatusDto.cellVoltageList[0]) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="单体电压2[V]" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.cellVoltageList &&
                        scope.row.ffBatteryStatusDto.cellVoltageList[1]) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="单体电压3[V]" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.cellVoltageList &&
                        scope.row.ffBatteryStatusDto.cellVoltageList[2]) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="单体电压4[V]" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.cellVoltageList &&
                        scope.row.ffBatteryStatusDto.cellVoltageList[3]) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="单体电压5[V]" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.cellVoltageList &&
                        scope.row.ffBatteryStatusDto.cellVoltageList[4]) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="单体电压6[V]" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.cellVoltageList &&
                        scope.row.ffBatteryStatusDto.cellVoltageList[5]) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="单体电压7[V]" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.cellVoltageList &&
                        scope.row.ffBatteryStatusDto.cellVoltageList[6]) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="单体电压8[V]" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.cellVoltageList &&
                        scope.row.ffBatteryStatusDto.cellVoltageList[7]) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="单体电压9[V]" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.cellVoltageList &&
                        scope.row.ffBatteryStatusDto.cellVoltageList[8]) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="单体电压10[V]" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.cellVoltageList &&
                        scope.row.ffBatteryStatusDto.cellVoltageList[9]) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="单体电压11[V]" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.cellVoltageList &&
                        scope.row.ffBatteryStatusDto.cellVoltageList[10]) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="单体电压12[V]" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.cellVoltageList &&
                        scope.row.ffBatteryStatusDto.cellVoltageList[11]) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="单体电压13[V]" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.cellVoltageList &&
                        scope.row.ffBatteryStatusDto.cellVoltageList[12]) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="单体电压14[V]" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.cellVoltageList &&
                        scope.row.ffBatteryStatusDto.cellVoltageList[13]) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="单体电压15[V]" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.cellVoltageList &&
                        scope.row.ffBatteryStatusDto.cellVoltageList[14]) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="单体电压16[V]" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.cellVoltageList &&
                        scope.row.ffBatteryStatusDto.cellVoltageList[15]) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="单体电压17[V]" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.cellVoltageList &&
                        scope.row.ffBatteryStatusDto.cellVoltageList[16]) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="单体电压18[V]" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.cellVoltageList &&
                        scope.row.ffBatteryStatusDto.cellVoltageList[17]) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="单体电压19[V]" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.cellVoltageList &&
                        scope.row.ffBatteryStatusDto.cellVoltageList[18]) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="单体电压20[V]" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.cellVoltageList &&
                        scope.row.ffBatteryStatusDto.cellVoltageList[19]) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="单体温度1[℃]" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.cellTempList &&
                        scope.row.ffBatteryStatusDto.cellTempList[0]) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="单体温度2[℃]" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.cellTempList &&
                        scope.row.ffBatteryStatusDto.cellTempList[1]) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="单体温度3[℃]" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.cellTempList &&
                        scope.row.ffBatteryStatusDto.cellTempList[2]) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="单体温度4[℃]" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.cellTempList &&
                        scope.row.ffBatteryStatusDto.cellTempList[3]) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="其他温度1[℃]" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.otherTempList &&
                        scope.row.ffBatteryStatusDto.otherTempList[0]) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="其他温度2[℃]" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.otherTempList &&
                        scope.row.ffBatteryStatusDto.otherTempList[1]) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="其他温度3[℃]" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.otherTempList &&
                        scope.row.ffBatteryStatusDto.otherTempList[2]) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="其他温度4[℃]" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.otherTempList &&
                        scope.row.ffBatteryStatusDto.otherTempList[3]) ||
                      "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="其他温度5[℃]" prop="status">
                <template slot-scope="scope">
                  {{
                      (scope.row.ffBatteryStatusDto &&
                        scope.row.ffBatteryStatusDto.otherTempList &&
                        scope.row.ffBatteryStatusDto.otherTempList[4]) ||
                      "-"
                  }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="电池溯源" name="second">
          <div class="battery-line">电池时间线</div>
          <el-timeline :reverse="reverse">
            <el-timeline-item v-for="(activity, index) in activities" :key="index" :timestamp="activity.time || '-'">
              {{ activity.title }}
            </el-timeline-item>
          </el-timeline>
        </el-tab-pane>
        <el-tab-pane label="当前故障信息" name="third">
          <div class="msg-list-tools">
            <el-form class="battery-form" :inline="true" :model="search">
              <el-form-item label="时间范围:">
                <el-date-picker v-model="search.time" type="datetimerange" range-separator="至" start-placeholder="开始日期"
                  end-placeholder="结束日期" @change="timeChange">
                </el-date-picker>
              </el-form-item>
              <div style="float: right">
                <el-form-item>
                  <el-button type="primary" size="small" @click="getFaultInfo">查询</el-button>
                </el-form-item>
                <el-form-item>
                  <el-button @click="resetForm()">重置</el-button>
                </el-form-item>
              </div>
            </el-form>
          </div>
          <div class="table-content">
            <el-table highlight-current-row stripe height="500px" :data="list" key="MenuList">
              <el-table-column prop="info" label="故障名称"> </el-table-column>
              <el-table-column prop="faultLevel" label="故障等级">
              </el-table-column>
              <el-table-column prop="faultTime" label="故障时间">
              </el-table-column>
              <el-table-column prop="source" :formatter="sourceStr" label="来源">
              </el-table-column>
            </el-table>
          </div>
          <div class="table-page">
            <div class="table-page-container">
              <el-pagination background :current-page="search.pageIndex" :page-sizes="[10, 20, 30, 40]"
                :page-size="search.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"
                @size-change="handleSizeChange" @current-change="handleCurrentChange">
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="定位查询" name="fourth" v-if="organId !== '99'">
          <div class="msg-list-tools">
            <el-form class="battery-form" :inline="true" :model="search">
              <el-form-item label="时间范围:">
                <el-date-picker v-model="search.time" type="datetimerange" range-separator="至" start-placeholder="开始日期"
                  end-placeholder="结束日期" @change="timeChange">
                </el-date-picker>
              </el-form-item>
              <!-- <el-form-item>
                <el-select v-model="search.type" @change="gpsSelectHandle">
                  <el-option label="图表" :value="1"></el-option>
                  <el-option label="列表" :value="2"></el-option>
                </el-select>
              </el-form-item> -->
              <!-- <el-form-item v-if="search.baseType === 2">
                <el-select v-model="search.baseLimit" @change="baseLimitSelectHandle">
                  <el-option label="100" :value="100"></el-option>
                  <el-option label="500" :value="500"></el-option>
                  <el-option label="1000" :value="1000"></el-option>
                  <el-option label="5000" :value="5000"></el-option>
                </el-select>
              </el-form-item> -->
              <div style="float: right">
                <el-form-item>
                  <el-button type="primary" size="small" @click="getData">查询</el-button>
                </el-form-item>
                <el-form-item>
                  <el-button @click="resetForm()">重置</el-button>
                </el-form-item>
              </div>
            </el-form>
          </div>
          <!-- <div
            class="gps-map"
            style="height: 600px; width: 1250px"
            id="gps-map"
            v-if="search.type === 1"
          /> -->
          <div class="table-content">
            <el-table highlight-current-row stripe height="500px" :data="dataList" key="MenuList">
              <el-table-column label="时间" width="200">
                <template slot-scope="scope">
                  {{ scope.row.infoDto.formatObdTime || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="经度">
                <template slot-scope="scope">
                  {{ scope.row.gpsDto.latitude || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="纬度">
                <template slot-scope="scope">
                  {{ scope.row.gpsDto.longitude || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="信号强度">
                <template slot-scope="scope">
                  {{ scope.row.gpsDto.satellites || 0 }}
                </template>
              </el-table-column>
              <el-table-column label="速度[km/h]">
                <template slot-scope="scope">
                  {{ scope.row.gpsDto.speed || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="海拔[m]">
                <template slot-scope="scope">
                  {{ scope.row.gpsDto.altitude || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="航向">
                <template slot-scope="scope">
                  {{
                      scope.row.gpsDto.direction === -1
                        ? "-"
                        : scope.row.gpsDto.direction
                  }}
                </template>
              </el-table-column>
              <el-table-column label="Lac">
                <template slot-scope="scope">
                  {{ scope.row.gpsDto.lac || "-" }}
                </template>
              </el-table-column>
              <el-table-column label="Cell">
                <template slot-scope="scope">
                  {{ scope.row.gpsDto.cell || "-" }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane label="维修信息" name="fifth">
          <div class="msg-list-tools">
            <el-form class="battery-form" :inline="true" :model="search">
              <el-form-item label="时间范围:">
                <el-date-picker
                  v-model="search.time"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="timeChange"
                >
                </el-date-picker>
              </el-form-item>
              <div style="float: right">
                <el-form-item>
                  <el-button type="primary" size="small" @click="getFixList"
                    >查询</el-button
                  >
                </el-form-item>
                <el-form-item>
                  <el-button @click="resetForm()">重置</el-button>
                </el-form-item>
              </div>
            </el-form>
          </div>
          <div class="table-content">
            <el-table
              highlight-current-row
              stripe
              height="500px"
              :data="list"
              key="MenuList"
            >
              <el-table-column prop="obdTime" label="序号"> </el-table-column>
              <el-table-column prop="latitude" label="时间"> </el-table-column>
              <el-table-column prop="longitude" label="问题类型">
              </el-table-column>
              <el-table-column prop="rssi" label="维修结果"> </el-table-column>
              <el-table-column prop="speed" label="维修人"> </el-table-column>
            </el-table>
          </div>
        </el-tab-pane> -->
        <!-- <el-tab-pane label="升级信息" name="sixth">
          <div class="msg-list-tools">
            <el-form class="battery-form" :inline="true" :model="search">
              <el-form-item label="时间范围:">
                <el-date-picker
                  v-model="search.time"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="timeChange"
                >
                </el-date-picker>
              </el-form-item>
              <div style="float: right">
                <el-form-item>
                  <el-button type="primary" size="small" @click="getData1"
                    >查询</el-button
                  >
                </el-form-item>
                <el-form-item>
                  <el-button @click="resetForm()">重置</el-button>
                </el-form-item>
              </div>
            </el-form>
          </div>
          <div class="table-content">
            <el-table
              highlight-current-row
              stripe
              height="500px"
              :data="list"
              key="MenuList"
            >
              <el-table-column prop="obdTime" label="序号"> </el-table-column>
              <el-table-column prop="latitude" label="版本号">
              </el-table-column>
              <el-table-column prop="longitude" label="起始时间">
              </el-table-column>
              <el-table-column prop="rssi" label="结束时间"> </el-table-column>
              <el-table-column prop="speed" label="升级状态"> </el-table-column>
              <el-table-column prop="speed" label="操作人"> </el-table-column>
            </el-table>
          </div>
        </el-tab-pane> -->
        <el-tab-pane label="历史异常状态" name="seventh">
          <div class="msg-list-tools">
            <el-form class="battery-form" :inline="true" :model="search">
              <el-form-item label="时间范围:">
                <el-date-picker v-model="search.time" type="datetimerange" range-separator="至" start-placeholder="开始日期"
                  end-placeholder="结束日期" @change="timeChange">
                </el-date-picker>
              </el-form-item>
              <div style="float: right">
                <el-form-item>
                  <el-button type="primary" size="small" @click="getHistroyList">查询</el-button>
                </el-form-item>
                <el-form-item>
                  <el-button @click="resetForm()">重置</el-button>
                </el-form-item>
              </div>
            </el-form>
          </div>
          <div class="table-content">
            <el-table highlight-current-row stripe height="500px" :data="list" key="MenuList">
              <el-table-column prop="type" :formatter="typeStr" label="异常信息类型">
              </el-table-column>
              <el-table-column prop="faultTime" label="发生时间">
              </el-table-column>
              <el-table-column prop="duraTime" label="持续时间">
              </el-table-column>
              <el-table-column prop="overTime" label="结束时间">
                <template slot-scope="scope">
                  {{ scope.row.overTime || "-" }}
                </template>
              </el-table-column>
              <el-table-column prop="source" :formatter="sourceStr" label="来源">
              </el-table-column>
              <el-table-column prop="updateBy" label="处理人">
              </el-table-column>
              <el-table-column label="操作" width="200">
                <template slot-scope="scope">
                  <el-button @click="infoHandle(scope.row)" type="text">查看详情</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="节电信息" name="eighth" style="height: 100%" v-if="organId === '99'">
          <div class="battery-echart">
            <div class="battery-echart-barLeft">
              <div class="bartimes">
                <div class="bartitle">累计节电收益</div>
                <div class="block">
                  <el-date-picker v-model="years" type="year" size="small" placeholder="选择年">
                  </el-date-picker>
                </div>
                <div class="block">
                  <el-switch style="display: block" @change="changeBatteryInfo()" v-model="months"
                    active-color="#8675ff" inactive-color="#ff4949" active-text="月" inactive-text="周">
                  </el-switch>
                </div>
              </div>
              <div class="battery-echart-bar" ref="batteryEchartBar"></div>
            </div>
            <div class="battery-echart-barRigt">
              <div class="linetitle">当日节电收益</div>
              <div class="battery-echart-line" ref="batteryEchartline"></div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog :visible.sync="visible" title="检测结果" :close-on-click-modal="false" :close-on-press-escape="false"
      width="40%">
      <div class="result-content" v-if="resultContent" v-html="resultContent"></div>
      <div class="result-content" v-else v-html="resultContent"></div>
    </el-dialog>
    <el-dialog :visible.sync="detailVisiable" title="历史异常状态详情" :close-on-click-modal="false"
      :close-on-press-escape="false" width="50%">
      <div class="handle-content">
        <div class="left-data">
          <div class="data-content">
            <div class="data-title">设备编号</div>
            <div class="data-text">{{ infoData.sn || "-" }}</div>
          </div>
          <div class="data-content">
            <div class="data-title">状态来源</div>
            <div class="data-text">
              {{ infoData.source === 1 ? "云控" : "客服" }}
            </div>
          </div>
          <div class="data-content">
            <div class="data-title">异常类型</div>
            <div class="data-text">
              {{
                  infoData.type === 1
                    ? "故障"
                    : infoData.type === 2
                      ? "低电量"
                      : "离线"
              }}
            </div>
          </div>
          <div class="data-content">
            <div class="data-title">当前运维状态</div>
            <div class="data-text">
              {{
                  infoData.ompStatus === 0
                    ? "正常"
                    : infoData.ompStatus === 1
                      ? "待处理"
                      : infoData.ompStatus === 2
                        ? "待回调"
                        : infoData.ompStatus === 3
                          ? "待售后"
                          : "已完结"
              }}
            </div>
          </div>
          <div class="data-content">
            <div class="data-title">发生时间</div>
            <div class="data-text">{{ infoData.faultTime || "-" }}</div>
          </div>
          <div class="data-content">
            <div class="data-title">回调时间</div>
            <div class="data-text">{{ infoData.callBackTime || "-" }}</div>
          </div>
          <div class="data-content">
            <div class="data-title">售后时间</div>
            <div class="data-text">{{ infoData.postSaleTime || "-" }}</div>
          </div>
          <div class="data-content">
            <div class="data-title">完结时间</div>
            <div class="data-text">{{ infoData.overTime || "-" }}</div>
          </div>
        </div>
        <div class="right-data" v-if="infoData.center">
          <div>客服记录</div>
          <div style="display: flex">
            <div style="line-height: 30px">
              {{ infoData.createTime || "-" }}
            </div>
            <div style="line-height: 30px; margin-left: 20px">
              {{ infoData.createBy || "-" }}
            </div>
          </div>
          <div style="display: flex">
            <div style="line-height: 30px">
              {{ infoData.createTime || "-" }}
            </div>
            <div style="line-height: 30px; margin-left: 20px">
              {{ infoData.createBy || "-" }}
            </div>
          </div>
          <div style="display: flex">
            <div style="line-height: 30px">
              {{ infoData.createTime || "-" }}
            </div>
            <div style="line-height: 30px; margin-left: 20px">
              {{ infoData.createBy || "-" }}
            </div>
          </div>
          <div style="display: flex">
            <div style="line-height: 30px">
              {{ infoData.createTime || "-" }}
            </div>
            <div style="line-height: 30px; margin-left: 20px">
              {{ infoData.createBy || "-" }}
            </div>
          </div>
        </div>

        <div class="right-data" v-if="infoData.faultLogList">
          <div>处理记录</div>
          <div class="items" v-for="(item, index) in infoData.faultLogList" :key="index">
            <div style="display: flex">
              <div style="line-height: 30px">{{ item.createTime || "-" }}</div>
              <div style="line-height: 30px; margin-left: 20px">
                {{ item.createBy || "-" }}
              </div>
            </div>
            <div style="line-height: 30px">{{ item.action }}</div>
            <div style="line-height: 30px">{{ item.remark || "-" }}</div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- <el-dialog :visible.sync="BmsIssueIsShow" :show-close="true" title="BMS脱扣器指令下发" width="35%">
      <el-form :model="ruleForm" :rules="rules" ref="BmsruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="设备离线"></el-form-item>
        <el-form-item label="被控对象" prop="remoteCtl">
          <el-select v-model="ruleForm.remoteCtl">
            <el-option label="0x00" value="0x00"></el-option>
            <el-option label="0x01" value="0x01"></el-option>
            <el-option label="0x02" value="0x02"></el-option>
            <el-option label="0x03" value="0x03"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数据长度" prop="remoteSize">
          <el-input v-model="ruleForm.remoteSize"></el-input>
        </el-form-item>
        <el-form-item label="读写指令" prop="remoteRw">
          <el-input v-model="ruleForm.remoteRw"></el-input>
        </el-form-item>
        <el-form-item label="DID_H" prop="remoteH">
          <el-input v-model="ruleForm.remoteH"></el-input>
        </el-form-item>
        <el-form-item label="DID_L" prop="remoteL">
          <el-input v-model="ruleForm.remoteL"></el-input>
        </el-form-item>
        <el-form-item label="Data" prop="remoteData">
          <el-input v-model="ruleForm.remoteData"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="BmssubmitForm('BmsruleForm')">设置</el-button>
          <el-button @click="BmsresetForm('BmsruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog> -->
    <LocationMap v-if="visable" ref="mapModel"></LocationMap>
  </div>
</template>

<script>
import L from "leaflet/dist/leaflet";
import "leaflet.chinatmsproviders";
import "leaflet/dist/leaflet.css";
import "./DeviceDetail.less";
let echarts = require("echarts/lib/echarts");
// 引入柱状图组件
require("echarts/lib/chart/bar");
require("echarts/lib/chart/line");
// 引入提示框和title组件
require("echarts/lib/component/tooltip");
require("echarts/lib/component/title");
import axios from "@/utils/axios";
import moment from "moment";
import LocationMap from "./components/map-location";
export default {
  name: "DeviceDetail",
  components: {
    LocationMap,
  },
  data() {
    return {
      visable: false,
      map: null,
      visible: false,
      detailVisiable: false,
      reverse: true,
      fullscreenLoading: false,
      sn: null,
      pageData: {},
      activeName: "first",
      activities: [],
      times: [],
      total: 0,
      search: {
        time: [],
        times: [],
        baseTime: [],
        imei: null,
        pageIndex: 1,
        pageSize: 20,
        endTime: null,
        startTime: null,
        endTimezd: null,
        startTimezd: null,
        order: -1,
        limit: 100,
        startTimeBase: null,
        endTimeBase: null,
        type: 1,
        baseType: 2,
        baseLimit: 100,
      },
      dataList: [],
      list: [],
      resultContent: null,
      chartsShow: false,
      infoData: {},
      baseList: null,
      baseLoading: false,
      showBase: false,
      organId: null,
      baseChangeType: 1,
      batteryInfoDataX: [
        { data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'] },
        { data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'] }
      ],
      batteryInfoDataY: [
        { data: [40, 64, 191, 324, 290, 330, 310, 213, 180, 200, 180, 79] },
        { data: [40, 64, 213, 180, 200, 180, 79] },
      ],
      years: '',
      months: true,
      Bmslock:0,
      BmsIssueIsShow: false,
      loading: false,
      
      // ruleForm: {
      //   remoteCtl: '0x00',
      //   remoteSize:'',
      //   remoteRw: '',
      //   remoteH: '',
      //   remoteL: '',
      //   remoteData: '',
      // },
      // rules: {
      //   remoteSize: [
      //     { required: true, message: '请输入数据长度', trigger: 'blur' },
      //     { min: 0, max: 80, message: '长度在 0 到 80 个字符', trigger: 'blur' }
      //   ],
      //   remoteRw: [
      //     { required: true, message: '请输入读写指令', trigger: 'change' }
      //   ],
      //   remoteH: [
      //     {required: true, message: '请输入DID_H', trigger: 'blur' }
      //   ],
      //   remoteL: [
      //     {required: true, message: '请输入DID_L', trigger: 'blur' }
      //   ],
      //   remoteData: [
      //     {required: true, message: '请输入指令内容', trigger: 'blur' }
      //   ]
      // }
    };
  },
  activated() {
    if (this.$route.params.type === 1) {
      this.sn = this.$route.params.sn;
      this.search.imei = this.$route.params.imei;
    }
    // this.getChartsDatas();
    if(this.search.baseType === 1) {
      this.getChartsDatas();
    } else {
      this.getFigureTableList();
    }
    this.getBatteryLine();
  },
  created() {
    this.organId = localStorage.getItem("organId");
    let d = new Date();
    if (localStorage.getItem("organId") === "99") {
      this.search.baseTime.push(d);
      // let year2, month2, day2;
      // let start_time = "2022-06-04";
      // year2 = start_time.substring(0, 4);
      // month2 = start_time.substring(5, 7);
      // day2 = start_time.substring(8, 10);
      // let date2 = new Date(year2, month2, day2, 0, 0, 0);
      let date3 = new Date(new Date().getTime() - 24*60*60*1000);
      this.search.baseTime.unshift(date3);
      this.search.startTimeBase = this.DateToStr(this.search.baseTime[0]);
      this.search.endTimeBase = this.DateToStr(this.search.baseTime[1]);
      this.getCurStatus();
    } else {
      let year1, month1, day1;
      [year1, month1, day1] = [d.getFullYear(), d.getMonth(), d.getDate()];
      let date1 = new Date(year1, month1, day1, 23, 59, 59);
      this.search.baseTime.push(date1);

      //前10天设定的日期时间
      let year2, month2, day2;
      d.setTime(d.getTime() - 1 * 24 * 60 * 60 * 1000);
      [year2, month2, day2] = [d.getFullYear(), d.getMonth(), d.getDate()];
      // let date2 = new Date(year2, month2, day2, 0);
      let date2 = new Date(year2, month2, day2, 23, 59, 59);
      this.search.baseTime.unshift(date2);
      this.search.startTimeBase = this.DateToStr(this.search.baseTime[0]);
      this.search.endTimeBase = this.DateToStr(this.search.baseTime[1]);
    }
  },
  mounted() { },
  watch: {
    //监听相同路由下参数变化的时候，从而实现异步刷新
    // $route(to, from) {
    //   //做一些路由变化的响应
    //   //打开加载动画
    //   this.getChartsDatas();
    //   this.getBatteryLine();
    // },
  },
  methods: {
    /**导出 */
    exportHandle() {
      let url = "/api/device/exportData";
      url += "?sn=" + this.sn
      url += "&startTime=" + this.search.startTimeBase
      url += "&endTime=" + this.search.endTimeBase
      url += "&organId=" + this.organId
      url += "&limit=" + 10000
      window.location.href = url;
    },
    //BMS脱扣器指令下发
    BmssetBatterye() {
      this.loading=true;
      let param ={
        sn: this.sn,
        command:2,
        lock:this.Bmslock,
        organId: this.organId
      }
      axios
        .post("/api/command/send", param)
        .then((res) => {
          this.loading=false
          if(this.Bmslock*1){
            this.Bmslock=0
          }else{
            this.Bmslock=1
          }
          this.$message.success(res); 
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    //获取当前设备的当前状态和租期
    getCurStatus() {
      let param = {
        sn: this.sn,
      };
      this.loading = true
      axios
        .post(`/api/command/getCurStatusAndRent`, param)
        .then((rs) => {
          this.Bmslock=rs.status
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          this.$message.error(err);
        });
    },
    // BmssubmitForm(formName) {
    //   this.$refs[formName].validate((valid) => {
    //     if (valid) {
    //       let param ={
    //         sn: this.sn,
    //         remoteCtl:this.ruleForm.remoteCtl,
    //         remoteSize:this.ruleForm.remoteSize,
    //         remoteRw:this.ruleForm.remoteRw,
    //         remoteH:this.ruleForm.remoteH,
    //         remoteL:this.ruleForm.remoteL,
    //         remoteData:this.ruleForm.remoteData,
    //         command:8,
    //         cmdType:'remote_cmd',
    //         organId:'99'
    //       }
    //       axios
    //         .post("/api/command/send", param)
    //         .then((res) => {
    //           console.log(res)
    //         })
    //         .catch((err) => {
    //           console.log(err);
    //           this.$message.error(err);
    //         });
    //     } else {
    //       return false;
    //     }
    //   });
    // },
    // BmsresetForm(formName) {
    //   this.$refs[formName].resetFields();
    // },
    clickHandle() {
      this.visable = true;
      this.$nextTick(() => {
        this.$refs.mapModel.init();
        this.$refs.mapModel.latitude = this.pageData.latitude;
        this.$refs.mapModel.longitude = this.pageData.longitude;
      });
    },

    showMoreHandle() {
      this.showBase = !this.showBase;
    },
    async initMap() {
      const normalm = L.tileLayer.chinaProvider("GaoDe.Normal.Map", {
        maxZoom: 18,
        minZoom: 1,
      });
      const normal = L.layerGroup([normalm]);
      this.$nextTick(async () => {
        const map = L.map("gps-map", {
          center: [40.125721, 116.320473],
          zoom: 5,
          layers: [normal],
          zoomControl: false,
        });
        let lngs = [];
        let { search } = this;
        search.batteryCode = this.sn;
        search.limit = 0;
        await axios
          .post(`/api/batteryChargeHistory/gps`, search)
          .then((rs) => {
            let dataList = rs;
            this.dataList = dataList;
            console.log(dataList);
            if (rs && rs.length > 0) {
              rs.forEach((item) => {
                if (
                  item.gpsDto &&
                  item.gpsDto.latitude &&
                  item.gpsDto.longitude
                ) {
                  let latlng = [];
                  latlng.push(item.gpsDto.latitude);
                  latlng.push(item.gpsDto.longitude);
                  lngs.push(latlng);
                }
              });
            }
          })
          .catch((err) => {
            this.$message.error(err);
          });

        let routeLine = L.polyline(lngs, { weight: 1, color: "blue" }).addTo(
          map
        );
        this.map = map;
      });
    },
    baseSelectHandle(value) {
      this.baseChangeType = value;
      if (value === 1) {
        this.getChartsDatas();
      } else {
        this.getFigureTableList();
      }
    },
    gpsSelectHandle(value) {
      if (value === 1) {
        this.initMap();
      } else {
        this.getData();
      }
    },
    baseLimitSelectHandle(value) {
      let param = {
        sn: this.sn,
        startTime: this.search.startTimeBase,
        endTime: this.search.endTimeBase,
        limit: value,
        organId: this.organId
      };
      this.baseLoading = true;
      axios
        .post("/api/device/detail/figureTable", param)
        .then((res) => {
          this.baseList = res;
          this.baseLoading = false;
        })
        .catch((err) => {
          this.baseLoading = false;
          this.$message.error(err);
        });
    },
    async getFigureTableList() {
      let param = {
        sn: this.sn,
        startTime: this.search.startTimeBase,
        endTime: this.search.endTimeBase,
        limit: this.search.baseLimit,
        organId: this.organId
      };
      this.baseLoading = true;
      await axios
        .post("/api/device/detail/figureTable", param)
        .then((res) => {
          this.baseList = res;
          this.baseLoading = false;
        })
        .catch((err) => {
          this.baseLoading = false;
          this.$message.error(err);
        });
    },
    infoHandle(row) {
      let param = {
        endTime: row.faultTime,
        startTime: row.overTime,
        sn: this.sn,
        id: row.id,
      };
      axios
        .post(`/api/device/latestInfo`, param)
        .then((rs) => {
          this.detailVisiable = true;
          this.infoData = rs;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    //返回上一页
    backToList() {
      this.$store.dispatch("tagsView/delView", this.$route);
      this.$router.go(-1);
    },
    handleClick(tab, event) {
      this.activeName = tab.name;
      this.list = null;
      this.search.startTime = null;
      this.search.endTime = null;
      this.search.type = 1;
      this.search.baseType = 1;
      this.search.time = [];
      if (tab.name === "first") {
        this.getChartsDatas();
      } else if (tab.name === "second") {
        this.getTimeLine();
      } else if (tab.name === "third") {
        this.getFaultInfo();
      } else if (tab.name === "fourth") {
        // this.$nextTick(() => {
        //   this.initMap();
        // })
        this.getData();
      } else if (tab.name === "fifth") {
        this.getFixList();
      } else if (tab.name === "seventh") {
        this.getHistroyList();
      } else if (tab.name === "eighth") {
        this.getBatteryInfoBar();
      }
    },
    resetForm() {
      this.search = {
        time: [],
        imei: this.search.imei,
        pageIndex: 1,
        pageSize: 20,
        endTime: null,
        startTime: null,
        order: -1,
        limit: 100,
      };
      if (this.activeName === "first") {
        this.getChartsDatas();
      } else if (this.activeName === "second") {
        this.getTimeLine();
      } else if (this.activeName === "third") {
        this.getFaultInfo();
      } else if (this.activeName === "fourth") {
        this.getData();
      } else if (this.activeName === "fifth") {
        this.getFixList();
      } else if (this.activeName === "seventh") {
        this.getHistroyList();
      } else if (tab.name === "eighth") {
        this.getBatteryInfoBar();
      }
    },
    getTimeLine() {
      let param = {
        sn: this.sn,
      };
      axios
        .post(`/api/device/deviceLine`, param)
        .then((rs) => {
          this.activities = rs.timeLine;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    getData1() { },
    sourceStr(row) {
      if (row) {
        if (row.source === 1) {
          return "系统";
        } else if (row.source === 2) {
          return "客服";
        } else {
          return "-";
        }
      } else {
        return "-";
      }
    },
    typeStr(row) {
      if (row) {
        if (row.source === 1) {
          return "故障";
        } else if (row.source === 2) {
          return "低电量";
        } else if (row.source === 3) {
          return "离线";
        } else {
          return "-";
        }
      } else {
        return "-";
      }
    },
    //时间选择
    timeChange(data) {
      if (data) {
        this.search.startTime = this.DateToStr(data[0]);
        this.search.endTime = this.DateToStr(data[1]);
      }
    },
    timeChanges(data) {
      if (data) {
        this.search.startTimezd = this.DateToStr(data[0]);
        this.search.endTimezd = this.DateToStr(data[1]);
      }
    },
    timeChangeBase(data) {
      if (data) {
        this.search.startTimeBase = this.DateToStr(data[0]);
        this.search.endTimeBase = this.DateToStr(data[1]);
      }
      if (this.baseChangeType === 1) {
        this.getChartsDatas();
      } else {
        this.getFigureTableList();
      }
    },
    //日期转字符串
    DateToStr(date) {
      var year = date.getFullYear(); //年
      var month = date.getMonth(); //月
      var day = date.getDate(); //日
      var hours = date.getHours(); //时
      var min = date.getMinutes(); //分
      var second = date.getSeconds(); //秒
      return (
        year +
        "-" +
        (month + 1 > 9 ? month + 1 : "0" + (month + 1)) +
        "-" +
        (day > 9 ? day : "0" + day) +
        " " +
        (hours > 9 ? hours : "0" + hours) +
        ":" +
        (min > 9 ? min : "0" + min) +
        ":" +
        (second > 9 ? second : "0" + second)
      );
    },
    //获取电池生命周期
    getBatteryLine() {
      let param = {
        sn: this.sn,
        organId:this.organId
      };
      axios
        .post(`/api/device/detail`, param)
        .then((rs) => {
          this.pageData = rs;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.search.pageIndex = val;
      this.getData();
    },
    //电池监测
    diagHandle(type) {
      const { times } = this.search;
      if (times.length === 0) {
        return this.$message.error("请选择时间");
      }
      let param = {
        command: type,
        imei: this.search.imei,
        sn: this.sn,
        startTime: this.search.startTimezd,
        endTime: this.search.endTimezd,
      };
      const loading = this.$loading({
        lock: true,
        text: "检测中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      axios
        .post(`/api/device/detail/diag`, param)
        .then((res) => {
          loading.close();
          this.visible = true;
          this.resultContent = res;
        })
        .catch((err) => {
          this.$message.error(err);
          loading.close();
        });
    },
    //获取当前故障信息
    getFaultInfo() {
      let { search } = this;
      search.sn = this.sn;
      axios
        .post(`/api/device/detail/curFault`, search)
        .then((res) => {
          this.total = res.total;
          this.list = res.list;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    //历史异常状态
    getHistroyList() {
      let { search } = this;
      search.sn = this.sn;
      axios
        .post(`/api/device/detail/hisFault`, search)
        .then((res) => {
          this.total = res.total;
          this.list = res.list;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    //维修信息
    getFixList() {
      let { search } = this;
      search.sn = this.sn;
      axios
        .post(`/api/device/detail/fixInfo`, search)
        .then((res) => {
          this.total = res.total;
          this.list = res.list;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    //查询定位信息
    getData() {
      let { search } = this;
      search.batteryCode = this.sn;
      axios
        .post(`/api/batteryChargeHistory/gps`, search)
        .then((rs) => {
          let dataList = rs;
          this.dataList = dataList;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    searchHandle() {
      let { search } = this;
      if (search.baseTime.length === 0) {
        return this.$message.error("请选择时间");
      }
      this.getChartsDatas();
    },
    
    getMinValue(list) {
      return Math.min.apply(Math, list);
    },

    getMinMoreValue(list) {
      let allList = [];
      list.forEach((item) => {
        allList.push(Math.min.apply(Math, item));
      });
      return Math.min.apply(Math, allList);
    },

    getMaxMoreValue(list) {
      let allList = [];
      list.forEach((item) => {
        allList.push(Math.max.apply(Math, item));
      });
      return Math.max.apply(Math, allList);
    },

    getMaxValue(list) {
      return Math.max.apply(Math, list);
    },

    //获取图标数据
    getChartsDatas() {
      let param = {
        sn: this.sn,
        startTime: this.search.startTimeBase,
        endTime: this.search.endTimeBase,
      };
      this.chartsShow = true;
      axios
        .post(`/api/device/detail/figure`, param)
        .then((rs) => {
          this.chartsShow = false;
          this.$nextTick(() => {
            let times = rs.time || [];
            let voltDiffChatrs = echarts.init(this.$refs.voltDiffChatrs);
            let voltDiffOption = {};
            if (rs.voltDiff.length > 0) {
              voltDiffOption = {
                tooltip: {
                  //鼠标悬浮框的提示文字
                  trigger: "axis",
                },
                legend: {
                  data: ["压差"],
                  textStyle: {
                    //图例文字的样式
                    color: "#000",
                    fontSize: 10,
                  },
                },
                dataZoom: [
                  {
                    type: "slider",
                    start: 0,
                    end: 100,
                  },
                  {
                    type: "inside",
                    start: 0,
                    end: 100,
                  },
                ],
                xAxis: {
                  data: times,
                },
                yAxis: {
                  min: (
                    this.getMinValue(rs.voltDiff) -
                    this.getMinValue(rs.voltDiff) * 0.05
                  ).toFixed(3),
                  max: (
                    this.getMaxValue(rs.voltDiff) +
                    this.getMaxValue(rs.voltDiff) * 0.05
                  ).toFixed(3),
                },

                series: [
                  {
                    data: rs.voltDiff,
                    type: "line",
                    smooth: true,
                  },
                ],
              };
            } else {
              this.$nextTick(() => {
                const dom = this.$refs.voltDiffChatrs;
                dom.innerHTML =
                  '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
                dom.removeAttribute("_echarts_instance_");
              });
            }
            let currentCharts = echarts.init(this.$refs.currentCharts);
            let currentOption = {};
            if (rs.current.length > 0) {
              currentOption = {
                tooltip: {
                  //鼠标悬浮框的提示文字
                  trigger: "axis",
                },
                legend: {
                  data: ["总电流"],
                  textStyle: {
                    //图例文字的样式
                    color: "#000",
                    fontSize: 10,
                  },
                },
                dataZoom: [
                  {
                    type: "slider",
                    start: 0,
                    end: 100,
                  },
                  {
                    type: "inside",
                    start: 0,
                    end: 100,
                  },
                ],
                xAxis: {
                  data: times,
                },
                yAxis: {
                  min: this.getMinValue(rs.current) - 2,
                  max: this.getMaxValue(rs.current) + 2,
                },

                series: [
                  {
                    data: rs.current,
                    type: "line",
                    smooth: true,
                  },
                ],
              };
            } else {
              this.$nextTick(() => {
                const dom = this.$refs.currentCharts;
                dom.innerHTML =
                  '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
                dom.removeAttribute("_echarts_instance_");
              });
            }
            let sohChatrs = echarts.init(this.$refs.sohChatrs);
            let sohOption = {};
            if (rs.soh.length > 0) {
              sohOption = {
                tooltip: {
                  //鼠标悬浮框的提示文字
                  trigger: "axis",
                },
                legend: {
                  data: ["SOH"],
                  textStyle: {
                    //图例文字的样式
                    color: "#000",
                    fontSize: 10,
                  },
                },
                dataZoom: [
                  {
                    type: "slider",
                    start: 0,
                    end: 100,
                  },
                  {
                    type: "inside",
                    start: 0,
                    end: 100,
                  },
                ],
                xAxis: {
                  data: times,
                },
                yAxis: {
                  min: this.getMinValue(rs.soh) - 2,
                  max: this.getMaxValue(rs.soh) + 2,
                },

                series: [
                  {
                    data: rs.soh,
                    type: "line",
                    smooth: true,
                  },
                ],
              };
            } else {
              this.$nextTick(() => {
                const dom = this.$refs.sohChatrs;
                dom.innerHTML =
                  '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
                dom.removeAttribute("_echarts_instance_");
              });
            }
            let socCharts = echarts.init(this.$refs.socCharts);
            let socOption = {};
            if (rs.soc.length > 0) {
              socOption = {
                tooltip: {
                  //鼠标悬浮框的提示文字
                  trigger: "axis",
                },
                legend: {
                  data: ["SOC"],
                  textStyle: {
                    //图例文字的样式
                    color: "#000",
                    fontSize: 10,
                  },
                },
                dataZoom: [
                  {
                    type: "slider",
                    start: 0,
                    end: 100,
                  },
                  {
                    type: "inside",
                    start: 0,
                    end: 100,
                  },
                ],
                xAxis: {
                  data: times,
                },
                yAxis: {
                  min: this.getMinValue(rs.soc) - 2,
                  max: this.getMaxValue(rs.soc) + 2,
                },

                series: [
                  {
                    data: rs.soc,
                    type: "line",
                    smooth: true,
                  },
                ],
              };
            } else {
              this.$nextTick(() => {
                const dom = this.$refs.socCharts;
                dom.innerHTML =
                  '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
                dom.removeAttribute("_echarts_instance_");
              });
            }
            let maxminTemp = echarts.init(this.$refs.maxminTemp);
            let maxminOption = {};
            if (rs.maxminTemp.length > 0) {
              let legends = [];
              let list = [];
              let k = 1;
              rs.maxminTemp.forEach((element) => {
                let series = {
                  name: k === 1 ? "max" : "min",
                  color: k === 1 ? "red" : "blue",
                  type: "line",
                  data: element,
                  smooth: true,
                  markLine: {
                    symbol: "none",
                    data: [
                      {
                        silent: false, //鼠标悬停事件  true没有，false有
                        lineStyle: {
                          //警戒线的样式  ，虚实  颜色
                          type: "solid",
                          color: "#3398DB",
                        },
                        yAxis: -30, // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                      },
                      {
                        silent: false, //鼠标悬停事件  true没有，false有
                        lineStyle: {
                          //警戒线的样式  ，虚实  颜色
                          type: "solid",
                          color: "#FA3934",
                        },
                        yAxis: 30, // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                      },
                    ],
                  },
                };
                let legend = k === 1 ? "max" : "min";
                legends.push(legend);
                list.push(series);
                k++;
              });
              maxminOption = {
                tooltip: {
                  //鼠标悬浮框的提示文字
                  trigger: "axis",
                },
                legend: {
                  data: legends,
                  textStyle: {
                    //图例文字的样式
                    color: "#000",
                    fontSize: 10,
                  },
                },
                dataZoom: [
                  {
                    type: "slider",
                    start: 0,
                    end: 100,
                  },
                  {
                    type: "inside",
                    start: 0,
                    end: 100,
                  },
                ],
                xAxis: {
                  data: times,
                },
                yAxis: {
                  min: this.getMinMoreValue(rs.maxminTemp),
                  max: this.getMaxMoreValue(rs.maxminTemp),
                },
                series: list,
              };
            } else {
              this.$nextTick(() => {
                const dom = this.$refs.maxminTemp;
                dom.innerHTML =
                  '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
                dom.removeAttribute("_echarts_instance_");
              });
            }
            let maxminVolt = echarts.init(this.$refs.maxminVolt);
            let maxVoltOption = {};
            if (rs.maxminVolt.length > 0) {
              let legends = [];
              let list = [];
              let k = 1;
              rs.maxminVolt.forEach((element) => {
                let series = {
                  name: k === 1 ? "max" : "min",
                  type: "line",
                  color: k === 1 ? "red" : "blue",
                  data: element,
                  smooth: true,
                  markLine: {
                    symbol: "none",
                    data: [
                      {
                        silent: false, //鼠标悬停事件  true没有，false有
                        lineStyle: {
                          //警戒线的样式  ，虚实  颜色
                          type: "solid",
                          color: "#3398DB",
                        },
                        yAxis: -30, // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                      },
                      {
                        silent: false, //鼠标悬停事件  true没有，false有
                        lineStyle: {
                          //警戒线的样式  ，虚实  颜色
                          type: "solid",
                          color: "#FA3934",
                        },
                        yAxis: 30, // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                      },
                    ],
                  },
                };
                let legend = k === 1 ? "max" : "min";
                legends.push(legend);
                list.push(series);
                k++;
              });
              maxVoltOption = {
                tooltip: {
                  //鼠标悬浮框的提示文字
                  trigger: "axis",
                },
                legend: {
                  data: legends,
                  textStyle: {
                    //图例文字的样式
                    color: "#000",
                    fontSize: 10,
                  },
                },
                dataZoom: [
                  {
                    type: "slider",
                    start: 0,
                    end: 100,
                  },
                  {
                    type: "inside",
                    start: 0,
                    end: 100,
                  },
                ],
                xAxis: {
                  data: times,
                },
                yAxis: {
                  min: this.getMinMoreValue(rs.maxminVolt),
                  max: this.getMaxMoreValue(rs.maxminVolt),
                },

                series: list,
              };
            } else {
              this.$nextTick(() => {
                const dom = this.$refs.maxminVolt;
                dom.innerHTML =
                  '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
                dom.removeAttribute("_echarts_instance_");
              });
            }
            let voltCharts = echarts.init(this.$refs.voltCharts);
            let voltOption = {};
            if (rs.volt.length > 0) {
              voltOption = {
                tooltip: {
                  //鼠标悬浮框的提示文字
                  trigger: "axis",
                },
                dataZoom: [
                  {
                    type: "slider",
                    start: 0,
                    end: 100,
                  },
                  {
                    type: "inside",
                    start: 0,
                    end: 100,
                  },
                ],
                xAxis: {
                  data: times,
                },
                yAxis: {
                  min: this.getMinValue(rs.volt),
                  max: this.getMaxValue(rs.volt),
                },

                series: [
                  {
                    data: rs.volt,
                    type: "line",
                    smooth: true,
                  },
                ],
              };
            } else {
              this.$nextTick(() => {
                let dom = this.$refs.voltCharts;
                dom.innerHTML =
                  '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
                dom.removeAttribute("_echarts_instance_");
              });
            }
            let otherTemp = echarts.init(this.$refs.otherTemp);
            let otherOption = {};
            if (rs.otherTemp.length > 0) {
              let legends = [];
              let list = [];
              let k = 1;
              rs.otherTemp.forEach((element) => {
                let series = {
                  name: "other-" + k,
                  type: "line",
                  data: element,
                  smooth: true,
                  markLine: {
                    symbol: "none",
                    data: [
                      {
                        silent: false, //鼠标悬停事件  true没有，false有
                        lineStyle: {
                          //警戒线的样式  ，虚实  颜色
                          type: "solid",
                          color: "#3398DB",
                        },
                        yAxis: -30, // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                      },
                      {
                        silent: false, //鼠标悬停事件  true没有，false有
                        lineStyle: {
                          //警戒线的样式  ，虚实  颜色
                          type: "solid",
                          color: "#FA3934",
                        },
                        yAxis: 30, // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                      },
                    ],
                  },
                };
                let legend = "other-" + k;
                legends.push(legend);
                list.push(series);
                k++;
              });
              otherOption = {
                tooltip: {
                  //鼠标悬浮框的提示文字
                  trigger: "axis",
                },
                legend: {
                  data: legends,
                  textStyle: {
                    //图例文字的样式
                    color: "#000",
                    fontSize: 10,
                  },
                },
                dataZoom: [
                  {
                    type: "slider",
                    start: 0,
                    end: 100,
                  },
                  {
                    type: "inside",
                    start: 0,
                    end: 100,
                  },
                ],
                xAxis: {
                  data: times,
                },
                yAxis: {
                  min: this.getMinMoreValue(rs.otherTemp),
                  max: this.getMaxMoreValue(rs.otherTemp),
                },

                series: list,
              };
            } else {
              this.$nextTick(() => {
                const dom = this.$refs.otherTemp;
                dom.innerHTML =
                  '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
                dom.removeAttribute("_echarts_instance_");
              });
            }
            let cellTemp = echarts.init(this.$refs.cellTemp);
            let cellTempOption = {};
            if (rs.cellTemp.length > 0) {
              let legends = [];
              let list = [];
              let k = 1;
              rs.cellTemp.forEach((element) => {
                let series = {
                  name: "cell-" + k,
                  type: "line",
                  data: element,
                  smooth: true,
                  markLine: {
                    symbol: "none",
                    data: [
                      {
                        silent: false, //鼠标悬停事件  true没有，false有
                        lineStyle: {
                          //警戒线的样式  ，虚实  颜色
                          type: "solid",
                          color: "#3398DB",
                        },
                        yAxis: -30, // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                      },
                      {
                        silent: false, //鼠标悬停事件  true没有，false有
                        lineStyle: {
                          //警戒线的样式  ，虚实  颜色
                          type: "solid",
                          color: "#FA3934",
                        },
                        yAxis: 30, // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                      },
                    ],
                  },
                };
                let legend = "cell-" + k;
                legends.push(legend);
                list.push(series);
                k++;
              });
              cellTempOption = {
                tooltip: {
                  //鼠标悬浮框的提示文字
                  trigger: "axis",
                },
                legend: {
                  data: legends,
                  textStyle: {
                    //图例文字的样式
                    color: "#000",
                    fontSize: 10,
                  },
                },
                dataZoom: [
                  {
                    type: "slider",
                    start: 0,
                    end: 100,
                  },
                  {
                    type: "inside",
                    start: 0,
                    end: 100,
                  },
                ],
                xAxis: {
                  data: times,
                },
                yAxis: {
                  min: this.getMinMoreValue(rs.cellTemp),
                  max: this.getMaxMoreValue(rs.cellTemp),
                },

                series: list,
              };
            } else {
              this.$nextTick(() => {
                const dom = this.$refs.cellTemp;
                dom.innerHTML =
                  '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
                dom.removeAttribute("_echarts_instance_");
              });
            }
            let cellVolt = echarts.init(this.$refs.cellVolt);
            let cellOption = {};
            if (rs.cellVolt.length > 0) {
              let legends = [];
              let list = [];
              let k = 1;
              rs.cellVolt.forEach((element) => {
                let series = {
                  name: "cell-" + k,
                  type: "line",
                  data: element,
                  smooth: true,
                  markLine: {
                    symbol: "none",
                    data: [
                      {
                        silent: false, //鼠标悬停事件  true没有，false有
                        lineStyle: {
                          //警戒线的样式  ，虚实  颜色
                          type: "solid",
                          color: "#3398DB",
                        },
                        yAxis: -30, // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                      },
                      {
                        silent: false, //鼠标悬停事件  true没有，false有
                        lineStyle: {
                          //警戒线的样式  ，虚实  颜色
                          type: "solid",
                          color: "#FA3934",
                        },
                        yAxis: 30, // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                      },
                    ],
                  },
                };
                let legend = "cell-" + k;
                legends.push(legend);
                list.push(series);
                k++;
              });
              cellOption = {
                tooltip: {
                  //鼠标悬浮框的提示文字
                  trigger: "axis",
                },
                legend: {
                  data: legends,
                  textStyle: {
                    //图例文字的样式
                    color: "#000",
                    fontSize: 10,
                  },
                },
                dataZoom: [
                  {
                    type: "slider",
                    start: 0,
                    end: 100,
                  },
                  {
                    type: "inside",
                    start: 0,
                    end: 100,
                  },
                ],
                xAxis: {
                  data: times,
                },
                yAxis: {
                  min: this.getMinMoreValue(rs.cellVolt),
                  max: this.getMaxMoreValue(rs.cellVolt),
                  type: "value",
                },

                series: list,
              };
            } else {
              this.$nextTick(() => {
                const dom = this.$refs.cellVolt;
                dom.innerHTML =
                  '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
                dom.removeAttribute("_echarts_instance_");
              });
            }
            voltDiffChatrs.setOption(voltDiffOption);
            currentCharts.setOption(currentOption);
            sohChatrs.setOption(sohOption);
            socCharts.setOption(socOption);
            maxminTemp.setOption(maxminOption);
            maxminVolt.setOption(maxVoltOption);
            voltCharts.setOption(voltOption);
            otherTemp.setOption(otherOption);
            cellTemp.setOption(cellTempOption);
            cellVolt.setOption(cellOption);
            echarts.connect([
              voltDiffChatrs,
              currentCharts,
              sohChatrs,
              socCharts,
              maxminTemp,
              maxminVolt,
              voltCharts,
              otherTemp,
              cellTemp,
              cellVolt,
            ]);
          });
        })
        .catch((err) => {
          this.chartsShow = false;
          this.$nextTick(() => {
            const dom = this.$refs.voltDiffChatrs;
            dom.innerHTML =
              '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
            dom.removeAttribute("_echarts_instance_");
            const domCurr = this.$refs.currentCharts;
            domCurr.innerHTML =
              '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
            domCurr.removeAttribute("_echarts_instance_");
            const domSoh = this.$refs.sohChatrs;
            domSoh.innerHTML =
              '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
            domSoh.removeAttribute("_echarts_instance_");
            const domSoc = this.$refs.socCharts;
            domSoc.innerHTML =
              '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
            domSoc.removeAttribute("_echarts_instance_");
            const dommaxTemp = this.$refs.maxminTemp;
            dommaxTemp.innerHTML =
              '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
            dommaxTemp.removeAttribute("_echarts_instance_");
            const domMaxVolt = this.$refs.maxminVolt;
            domMaxVolt.innerHTML =
              '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
            domMaxVolt.removeAttribute("_echarts_instance_");
            const domVolt = this.$refs.voltCharts;
            domVolt.innerHTML =
              '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
            domVolt.removeAttribute("_echarts_instance_");
            const domOther = this.$refs.otherTemp;
            domOther.innerHTML =
              '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
            domOther.removeAttribute("_echarts_instance_");
            const domCellTemp = this.$refs.cellTemp;
            domCellTemp.innerHTML =
              '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
            domCellTemp.removeAttribute("_echarts_instance_");
            const domCellVolt = this.$refs.cellVolt;
            domCellVolt.innerHTML =
              '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
            domCellVolt.removeAttribute("_echarts_instance_");
          });
          this.$message.error(err);
        });
    },
    changeBatteryInfo() {
      if (this.months) {
        this.getBatteryInfoBar(this.batteryInfoDataX[0].data, this.batteryInfoDataY[0].data)
      } else {
        this.getBatteryInfoBar(this.batteryInfoDataX[1].data, this.batteryInfoDataY[1].data)
      }

    },
    //获取节电信息图表数据
    getBatteryInfoBar(barArrayX, barArrayY) {
      this.chartsShow = true;
      let batteryEchartBar = echarts.init(this.$refs.batteryEchartBar);
      let batteryEchartline = echarts.init(this.$refs.batteryEchartline);

      // 2. 指定配置和数据
      var baroption = {
        color: ["#5470c6"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          left: "0%",
          top: "10px",
          right: "0%",
          bottom: "4%",
          containLabel: true
        },
        xAxis: [
          {
            type: "category",
            data: this.batteryInfoDataX[0].data,
            axisTick: {
              show: true,
              alignWithLabel: true
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#6e7079",
                fontSize: "12"
              }
            },
            axisLine: {
              show: true
            }
          }
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              textStyle: {
                color: "#6e7079",
                fontSize: "12"
              }
            },

            splitLine: {
              lineStyle: {
                color: "#e8ecf5"
              }
            }
          }
        ],
        series: [
          {
            name: "用电电量",
            type: "bar",
            barWidth: "65%",
            data: this.batteryInfoDataY[0].data,
            label: {
              show: true,
              position: 'top',
              textStyle: { // 数值样式
                fontSize: 12
              }
            },
            itemStyle: {
              barBorderRadius: 3
            }
          }
        ]
      };
      var data = [70, 34, 60, 78];
      var titlename = ["充电能量", "放电能量", "负载能量", "累计节电费"];
      var myColor = ["#5470c6"];
      var lineoption = {
        grid: {
          top: "3%",
          left: "2%",
          right: "1%",
          bottom: "0%"
        },
        xAxis: {
          show: false
        },
        yAxis: [
          {
            show: true,
            data: titlename,
            inverse: true,
            axisLine: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              interval: 2,
              color: "#333333",
              fontSize: 14,
              fontWeight: 'bold'
            }
          },
          {
            show: true,
            inverse: true,
            data: data,
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              interval: 0,
              show: false,
              textStyle: {
                fontSize: 14,
                color: "#333333",
                fontWeight: 'bold',
              }
            }
          }
        ],
        series: [
          {
            name: "框",
            type: "bar",
            yAxisIndex: 1,
            barCategoryGap: 60,
            data: [100, 100, 100, 100],
            barWidth: 40,
            label: {
              show: true,
              formatter: '{b}',
              position: 'right',
              align: 'center',
              offset: [-25, -40],
              fontSize: 14,
              fontWeight: 'bold',
            },
            itemStyle: {
              normal: {
                barBorderRadius: 50,
                color: "#dcdeef",
              }
            }
          },
          {
            name: "条",
            type: "bar",
            yAxisIndex: 0,
            data: data,
            barCategoryGap: 50,
            barWidth: 40,
            label: {
              show: true,
              formatter: '{b}',
              position: 'left',
              align: 'left',
              offset: [15, -40],
              fontSize: 14,
              fontWeight: 'bold',
            },
            itemStyle: {
              normal: {
                barBorderRadius: 30,
                color: function (params) {
                  var num = myColor.length;
                  return myColor[params.dataIndex % num];
                }
              }
            },
          },
        ]
      };
      batteryEchartBar.setOption(baroption);
      batteryEchartline.setOption(lineoption);
      if (barArrayX && barArrayY) {
        baroption.xAxis[0].data = barArrayX;
        baroption.series[0].data = barArrayY;
        batteryEchartBar.setOption(baroption);
      }
    },
  },
  filters: {
    rentalModeStr(value) {
      let res = "-";
      if (value && value === 1) {
        res = "租赁模式";
      } else if (value && value === 2) {
        res = "零售模式";
      }
      return res;
    },
    chargeStateStr(value) {
      let res = "-";
      if (value === 1) {
        res = "快充";
      } else if (value === 2) {
        res = "慢充";
      } else if (value === 3) {
        res = "行车";
      } else if (value === 0) {
        res = "停车";
      }
      return res;
    },
    runStatusStr(value) {
      let res = "-";
      if (value === 0) {
        res = "rest";
      } else if (value === 3) {
        res = "放电";
      } else {
        res = "充电";
      }
      return res;
    },
    lockedStr(value) {
      let res = "-";
      if (value === 1) {
        res = "锁定";
      } else if (value === 0) {
        res = "解锁";
      }
      return res;
    },
  },
};
</script>